import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import DynamicForm from "../../../components/forms/dynamic-form"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageFluid from "../../../components/misc/image-fluid";
import PartnersData from "../../../content/partners/apply/index.json";
import Testimonial from "../../../components/misc/testimonial"
import PartnersTicker from "../../../components/misc/partners-ticker"

const PartnersIndexPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={PartnersData[intl.locale].title} description={PartnersData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={PartnersData[intl.locale]} />
        <div className={`demo`}>
            <Container>
                <Row noGutters={true}>
                    <Col md={4}>
                        <div className={`top-small-overflow-wrapper`}>
                            <div className={`demo-content`}>
                                <div className={`title`}>{PartnersData[intl.locale].content.title}</div>
                                <div className={`reasons`}>
                                    {PartnersData[intl.locale].content.reasons.map((reason, i) => {
                                        return (
                                        <div key={i} className={`reason`}>
                                            <FontAwesomeIcon icon="arrow-right" />
                                            {reason.text}
                                        </div>
                                        )
                                    })}
                                </div>
                                <div className={`image`}><ImageFluid alt={``} filename={PartnersData[intl.locale].content.image} /></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`demo-form`}>
                                <DynamicForm type={`partnerRequest`} redirect={`/forms/thank-you/`} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className={`bg-light`}>
            <Container>
                <Testimonial content={PartnersData[intl.locale].testimonial}/>
            </Container>
        </div>
        <PartnersTicker content={PartnersData[intl.locale].ticker} />
    </Layout>
)

export default injectIntl(PartnersIndexPage)
